const Config = {
  // BASE_API_URL: "http://127.0.0.1:3000/", // LOCAL URL
  // BASE_API_URL: "http://35.154.18.1:3000/", // MAIN URL
  BASE_API_URL: "https://api.urbanlyfe.in/", // MAIN URL

  // Firebase Config
  FIREBASE_API_KEY: "AIzaSyCBey_gDcBnsUq7ZBxrIaJ0bCjNb5asGqY",
  FIREBASE_AUTH_DOMAIN: "dev-urbanlyfe-merchant.firebaseapp.com",
  FIREBASE_PROJECT_ID: "dev-urbanlyfe-merchant",
  FIREBASE_STORAGE_BUCKET: "dev-urbanlyfe-merchant.appspot.com",
  FIREBASE_MESSAGING_SENDER_ID: "382158828781",
  FIREBASE_APP_ID: "1:450628718980:web:a94b7c4993c3983ebfcdd1",
};

export default Config;
